<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.rejected_participant_list')}} {{$t('globalTrans.search')}}</h4>
          </template>
          <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.applied_org')}} <span class="text-danger">*</span>
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.applied_org_id"
                        :options="orgList"
                        id="applied_org_id"
                        :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                      <v-select name="applied_org_id"
                        v-model="search.applied_org_id"
                        :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.profession_type')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.profession_type"
                        :options="professionTypeList"
                        id="training_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" v-if="search.profession_type === 1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                    >
                      <template v-slot:label>
                        {{$t('externalUserIrrigation.organization')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                      <v-select name="org_id"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.from_date')"
                        label-for="form_date"
                        >
                        <b-form-input
                            v-model="search.form_date"
                            placeholder="Select Date"
                            id="datepicker"
                        ></b-form-input>
                        </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.to_date')"
                      label-for="to_date"
                      >
                      <b-form-input
                          v-model="search.to_date"
                          placeholder="Select Date"
                          id="datepicker"
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalUserData.mobile_no')"
                      label-for="mobile"
                      >
                      <b-form-input
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        id="mobile"
                        v-model="search.mobile"
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                  <!-- <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="status"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.status')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.status"
                        :options="statusList"
                        id="status"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                  </b-col> -->
                  <b-col md="2" sm="12">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.rejected_participant_list')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button class="btn_add_new mr-2" @click="pdfExport">
                          <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12">
                                  <div class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(name)="data">
                                          <span class="capitalize">{{ data.item.name }}</span>
                                        </template>
                                        <template v-slot:cell(image)="data">
                                          <img v-if="data.item.image" :src="trainingElearningServiceBaseUrl + 'storage/' + data.item.image" style="width: 80px; height: 80px; border-radius: 50% !important;" class="center">
                                        </template>
                                        <template v-slot:cell(signature)="data">
                                          <img v-if="data.item.signature" :src="trainingElearningServiceBaseUrl + 'storage/' + data.item.signature" style="width: 80px; height: 40px; border-radius: 0px !important;" class="center">
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(nid)="data">
                                          <slot v-if="data.item.nid">
                                            {{ $n(data.item.nid, { useGrouping: false }) }}
                                          </slot>
                                        </template>
                                        <template v-slot:cell(date_of_birth)="data">
                                          <span class="capitalize">{{ data.item.date_of_birth | dateFormat }}</span>
                                        </template>
                                        <!-- <template v-slot:cell(org_name)="data">
                                          <span v-if="data.item.professional_info.profession_type != 1">
                                            {{ ($i18n.locale=='bn') ? data.item.professional_info.other_org_name_bn : data.item.professional_info.other_org_name }}
                                          </span>
                                          <span v-if="data.item.professional_info.profession_type == 1">
                                            {{ ($i18n.locale=='bn') ? data.item.org_name_bn : data.item.org_name }}
                                          </span>
                                        </template> -->
                                        <template v-slot:cell(designation)="data">
                                          <span v-if="data.item.professional_info.designation_id && data.item.professional_info.not_here_designation == 0 && data.item.professional_info.profession_type == 1">{{ getDesignation(data.item.professional_info.designation_id) }}</span>
                                          <span v-else>{{ ($i18n.locale=='bn') ? data.item.professional_info.designation_bn : data.item.professional_info.designation_en}}</span>
                                        </template>
                                        <template v-slot:cell(office)="data">
                                          <span v-if="data.item.professional_info.profession_type == 1 && data.item.professional_info.not_here_office == 0">{{ getOfficeName(data.item.professional_info.office_id) + ', ' + getOrgName(data.item.professional_info.org_id) }}</span>
                                          <span v-else-if="data.item.professional_info.profession_type == 1 && data.item.professional_info.not_here_office == 1">{{ (($i18n.locale=='bn') ? data.item.professional_info.office_name_bn : data.item.professional_info.office_name) }}</span>
                                          <span v-else-if="data.item.professional_info.profession_type != 1">{{ ($i18n.locale=='bn') ? data.item.professional_info.other_org_name_bn : data.item.professional_info.other_org_name }}</span>
                                          <!-- <span v-if="data.item.professional_info.org_id">{{ getOfficeName(data.item.professional_info.office_id) + ' , ' + getOrgName(data.item.professional_info.org_id) }}</span> -->
                                        </template>
                                        <template v-slot:cell(email)="data">
                                          <span>{{ data.item.email }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('globalTrans.')}}</span>
                                            <span class="badge badge-danger" v-else-if="data.item.status == 2">{{$t('globalTrans.reject')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('globalTrans.approved')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                            <span v-if="data.item.status < 3">
                                                <a href="javascript:" class="btn_table_action table_action_status" title="Approve" @click="approve(data.item)"><i class="fa fa-check"></i></a>
                                            </span>
                                        </template>
                                    </b-table>
                                    </div>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_iabm.trainee_registration') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :item="item" :key="id" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { rejectedList, personalInfoApprove } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import ExportPdf from './export_pdf_details'
import Store from '@/store'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details
    },
    data () {
        return {
            search: {
              profession_type: 0,
              org_id: null,
              applied_org_id: null,
              trainee: 1,
              status: 2,
              mobile: '',
              form_date: '',
              to_date: ''
            },
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            id: 0,
            item: '',
            myFilter: (option, text, search) => {
                    const temp = search.toLowerCase()
                    return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                    option.text_bn.toLowerCase().indexOf(temp) > -1
                }
        }
    },
    computed: {
        userTypeList () {
            const userList = [
              { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
              { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
            ]
            return userList
        },
        professionTypeList: function () {
          return [
            {
              value: 1,
              text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
              text_en: 'MoA/Organization under MoA',
              text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
            },
            {
              value: 2,
              text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
              text_en: 'Other Government Organization',
              text_bn: 'অন্যান্য সরকারী সংস্থা'
            },
            {
              value: 3,
              text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
              text_en: 'Non-Goverment Organization',
              text_bn: 'বেসরকারি সংস্থা'
            }
          ]
        },
        statusList: function () {
          return [
            {
              value: 1,
              text: this.$i18n.locale === 'bn' ? 'বিচারাধীন' : 'Pending',
              text_en: 'Pending',
              text_bn: 'বিচারাধীন'
            },
            {
              value: 2,
              text: this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যাত' : 'Rejected',
              text_en: 'Rejected',
              text_bn: 'প্রত্যাখ্যাত'
            },
            {
              value: 3,
              text: this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved',
              text_en: 'Approved',
              text_bn: 'অনুমোদিত'
            }
          ]
        },
        genderList () {
        const genderList = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ', text_en: 'Male', text_bn: 'পুরুষ' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা', text_en: 'Female', text_bn: 'মহিলা' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
        ]
        return genderList
        },
        maritalList () {
        const status = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত', text_en: 'Married', text_bn: 'বিবাহিত' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত', text_en: 'Unmarried', text_bn: 'অবিবাহিত' }
        ]
        return status
        },
        bloodGroupList () {
          const bloodGroupList = [
            { value: 1, text: 'A+', text_bn: 'এ+' },
            { value: 2, text: 'A-', text_bn: 'এ-' },
            { value: 3, text: 'B+', text_bn: 'বি+' },
            { value: 4, text: 'B-', text_bn: 'বি-' },
            { value: 5, text: 'O+', text_bn: 'ও+' },
            { value: 6, text: 'O-', text_bn: 'ও-' },
            { value: 7, text: 'AB+', text_bn: 'এবি+' },
            { value: 8, text: 'AB-', text_bn: 'এবি-' }
        ]
        return bloodGroupList
        },
        religionList () {
        const religionList = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম', text_en: 'Muslim', text_bn: 'মুসলিম' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু', text_en: 'Hindu', text_bn: 'হিন্দু' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান', text_en: 'Christian', text_bn: 'খ্রিস্টান' },
            { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ', text_en: 'Buddhist', text_bn: 'বৌদ্ধ' },
            { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
        ]
        return religionList
        },
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        officeTypeList: function () {
            return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        },
        officeList: function () {
            return this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('elearning_config.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.photo'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('globalTrans.designation'), class: 'text-center' },
                // { label: this.$t('globalTrans.organization'), class: 'text-center' },
                { label: this.$t('elearning_tpm.work_place'), class: 'text-center' },
                { label: this.$t('globalTrans.mobile'), class: 'text-center' },
                { label: this.$t('globalTrans.email'), class: 'text-center wid' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'image' },
                { key: 'name_bn' },
                { key: 'designation' },
                // { key: 'org_name' },
                { key: 'office' },
                { key: 'mobile' },
                { key: 'email' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'image' },
                { key: 'name' },
                { key: 'designation' },
                // { key: 'org_name' },
                { key: 'office' },
                { key: 'mobile' },
                { key: 'email' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
      let orgiId = null
      if (this.$store.state.Auth.activeRoleId !== 1) {
          orgiId = this.$store.state.Auth.authUser.org_id
      }
      this.search = Object.assign({}, this.search, {
        applied_org_id: orgiId
      })
      this.loadData()
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    methods: {
        getOrgName (id) {
          const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.abbreviation_bn : ''
          } else {
            return trainingType !== undefined ? trainingType.abbreviation : ''
          }
        },
        getDesignation (id) {
          const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
            return designation !== undefined ? designation.text_bn : ''
          } else {
            return designation !== undefined ? designation.text_en : ''
          }
        },
        getOfficeName (id) {
          const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
          } else {
            return office !== undefined ? office.text_en : ''
          }
        },
        dataChange () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        async searchData () {
            this.loadData()
        },
        changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}/${2}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              if (destination !== null && dropdownName !== null) {
                Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
              }
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        changeAppliedStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.personal_info_applied_table_id}/${2}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              if (destination !== null && dropdownName !== null) {
                Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
              }
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        approve (item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.approveMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeAppliedStatus(trainingElearningServiceBaseUrl, personalInfoApprove, item, 'training_e_learning', 'trainerEvaluationList')
            }
          })
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, rejectedList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const customItem = {}
            const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
            if (typeof userTypeObj !== 'undefined') {
              customItem.user_type_name = userTypeObj.text_en
              customItem.user_type_name_bn = userTypeObj.text_bn
            } else {
              customItem.user_type_name = ''
              customItem.user_type_name_bn = ''
            }
            const orgItem = {}
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.professional_info.org_id)
            if (typeof orgObj !== 'undefined') {
              orgItem.org_name = orgObj.text_en
              orgItem.org_name_bn = orgObj.text_bn
            } else {
              orgItem.org_name = ''
              orgItem.org_name_bn = ''
            }
            const designationItem = {}
            const designationObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.professional_info.designation_id))
            if (typeof designationObj !== 'undefined') {
              designationItem.designation_name = designationObj.text_en
              designationItem.designation_name_bn = designationObj.text_bn
            } else {
              designationItem.designation_name = ''
              designationItem.designation_name_bn = ''
            }
            const jobTypeItem = {}
            const jobTypeObj = this.$store.state.TrainingElearning.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(item.professional_info.job_type_id))
            if (typeof jobTypeObj !== 'undefined') {
              jobTypeItem.job_name = jobTypeObj.text_en
              jobTypeItem.job_name_bn = jobTypeObj.text_bn
            } else {
              jobTypeItem.job_name = ''
              jobTypeItem.job_name_bn = ''
            }
            const gradeItem = {}
            const gradeObj = this.$store.state.commonObj.gradeList.find(grade => grade.value === item.professional_info.grade_id)
            if (typeof gradeObj !== 'undefined') {
              gradeItem.grade_name = gradeObj.text_en
              gradeItem.grade_name_bn = gradeObj.text_bn
            } else {
              gradeItem.grade_name = ''
              gradeItem.grade_name_bn = ''
            }
            const areaItem = {}
            const areaObj = this.$store.state.commonObj.wardTypeList.find(area => area.id === item.address.pre_area_type_id)
            if (typeof areaObj !== 'undefined') {
              areaItem.area_name = areaObj.name
              areaItem.area_name_bn = areaObj.name_bn
            } else {
              areaItem.area_name = ''
              areaItem.area_name_bn = ''
            }
            const perareaItem = {}
            const perareaObj = this.$store.state.commonObj.wardTypeList.find(area => area.id === item.address.per_area_type_id)
            if (typeof perareaObj !== 'undefined') {
              perareaItem.per_area_name = perareaObj.name
              perareaItem.per_area_name_bn = perareaObj.name_bn
            } else {
              perareaItem.per_area_name = ''
              perareaItem.per_area_name_bn = ''
            }
            const divisionItem = {}
            const divisionObj = this.$store.state.commonObj.divisionList.find(division => division.value === item.address.pre_division_id)
            if (typeof divisionObj !== 'undefined') {
              divisionItem.division_name = divisionObj.text_en
              divisionItem.division_name_bn = divisionObj.text_bn
            } else {
              divisionItem.division_name = ''
              divisionItem.division_name_bn = ''
            }
            const perdivisionItem = {}
            const perdivisionObj = this.$store.state.commonObj.divisionList.find(division => division.value === item.address.per_division_id)
            if (typeof perdivisionObj !== 'undefined') {
              perdivisionItem.per_division_name = perdivisionObj.text_en
              perdivisionItem.per_division_name_bn = perdivisionObj.text_bn
            } else {
              perdivisionItem.per_division_name = ''
              perdivisionItem.per_division_name_bn = ''
            }
            const districtItem = {}
            const districtObj = this.$store.state.commonObj.districtList.find(district => district.value === item.address.pre_district_id)
            if (typeof divisionObj !== 'undefined') {
              districtItem.district_name = districtObj.text_en
              districtItem.district_name_bn = districtObj.text_bn
            } else {
              districtItem.district_name = ''
              districtItem.district_name_bn = ''
            }
            const perdistrictItem = {}
            const perdistrictObj = this.$store.state.commonObj.districtList.find(district => district.value === item.address.per_district_id)
            if (typeof perdivisionObj !== 'undefined') {
              perdistrictItem.per_district_name = perdistrictObj.text_en
              perdistrictItem.per_district_name_bn = perdistrictObj.text_bn
            } else {
              perdistrictItem.per_district_name = ''
              perdistrictItem.per_district_name_bn = ''
            }
            const upazillaItem = {}
            const upazillaObj = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === item.address.pre_upazilla_id)
            if (typeof upazillaObj !== 'undefined') {
              upazillaItem.upazilla_name = upazillaObj.text_en
              upazillaItem.upazilla_name_bn = upazillaObj.text_bn
            } else {
              upazillaItem.upazilla_name = ''
              upazillaItem.upazilla_name_bn = ''
            }
            const perupazillaItem = {}
            const perupazillaObj = this.$store.state.commonObj.upazilaList.find(perupazilla => perupazilla.value === item.address.per_upazilla_id)
            if (typeof perupazillaObj !== 'undefined') {
              perupazillaItem.per_upazilla_name = perupazillaObj.text_en
              perupazillaItem.per_upazilla_name_bn = perupazillaObj.text_bn
            } else {
              perupazillaItem.per_upazilla_name = ''
              perupazillaItem.per_upazilla_name_bn = ''
            }
            const cityCorporationItem = {}
            const cityCorporationObj = this.$store.state.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === item.address.pre_city_corporation_id)
            if (typeof cityCorporationObj !== 'undefined') {
              cityCorporationItem.cityCorporation_name = cityCorporationObj.text_en
              cityCorporationItem.cityCorporation_name_bn = cityCorporationObj.text_bn
            } else {
              cityCorporationItem.cityCorporation_name = ''
              cityCorporationItem.cityCorporation_name_bn = ''
            }
            const percityCorporationItem = {}
            const percityCorporationObj = this.$store.state.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === item.address.pre_city_corporation_id)
            if (typeof percityCorporationObj !== 'undefined') {
              percityCorporationItem.per_cityCorporation_name = percityCorporationObj.text_en
              percityCorporationItem.per_cityCorporation_name_bn = percityCorporationObj.text_bn
            } else {
              percityCorporationItem.per_cityCorporation_name = ''
              percityCorporationItem.per_cityCorporation_name_bn = ''
            }
            const unionItem = {}
            const unionObj = this.$store.state.commonObj.unionList.find(union => union.value === item.address.pre_union_id)
            if (typeof unionObj !== 'undefined') {
              unionItem.union_name = unionObj.text_en
              unionItem.union_name_bn = unionObj.text_bn
            } else {
              unionItem.union_name = ''
              unionItem.union_name_bn = ''
            }
            const perunionItem = {}
            const perunionObj = this.$store.state.commonObj.unionList.find(union => union.value === item.address.per_union_id)
            if (typeof perunionObj !== 'undefined') {
              perunionItem.per_union_name = perunionObj.text_en
              perunionItem.per_union_name_bn = perunionObj.text_bn
            } else {
              perunionItem.per_union_name = ''
              perunionItem.per_union_name_bn = ''
            }
            const wardItem = {}
            const wardObj = this.$store.state.commonObj.wardList.find(ward => ward.value === item.address.pre_ward_id)
            if (typeof wardObj !== 'undefined') {
              wardItem.ward_name = wardObj.text_en
              wardItem.ward_name_bn = wardObj.text_bn
            } else {
              wardItem.ward_name = ''
              wardItem.ward_name_bn = ''
            }
            const perwardItem = {}
            const perwardObj = this.$store.state.commonObj.wardList.find(ward => ward.value === item.address.per_ward_id)
            if (typeof perwardObj !== 'undefined') {
              perwardItem.per_ward_name = perwardObj.text_en
              perwardItem.per_ward_name_bn = perwardObj.text_bn
            } else {
              perwardItem.per_ward_name = ''
              perwardItem.per_ward_name_bn = ''
            }
            const pauroshobaItem = {}
            const pauroshobaObj = this.$store.state.commonObj.pauroshobaList.find(per => per.value === item.address.pre_pauroshoba_id)
            if (typeof pauroshobaObj !== 'undefined') {
              pauroshobaItem.pauroshoba_name = pauroshobaObj.text_en
              pauroshobaItem.pauroshoba_name_bn = pauroshobaObj.text_bn
            } else {
              pauroshobaItem.pauroshoba_name = ''
              pauroshobaItem.pauroshoba_name_bn = ''
            }
            const perpauroshobaItem = {}
            const perpauroshobaObj = this.$store.state.commonObj.pauroshobaList.find(per => per.value === item.address.per_pauroshoba_id)
            if (typeof perpauroshobaObj !== 'undefined') {
              perpauroshobaItem.per_pauroshoba_name = perpauroshobaObj.text_en
              perpauroshobaItem.per_pauroshoba_name_bn = perpauroshobaObj.text_bn
            } else {
              perpauroshobaItem.per_pauroshoba_name = ''
              perpauroshobaItem.per_pauroshoba_name_bn = ''
            }
            const genderItem = {}
            const genderObj = this.genderList.find(per => per.value === item.gender)
            if (typeof genderObj !== 'undefined') {
              genderItem.gender_name = genderObj.text_en
              genderItem.gender_name_bn = genderObj.text_bn
            } else {
              genderItem.gender_name = ''
              genderItem.gender_name_bn = ''
            }
            const proTypeItem = {}
            const proTypeObj = this.professionTypeList.find(per => per.value === parseInt(item.professional_info.profession_type))
            if (typeof proTypeObj !== 'undefined') {
              proTypeItem.proType_name = proTypeObj.text_en
              proTypeItem.proType_name_bn = proTypeObj.text_bn
            } else {
              proTypeItem.proType_name = ''
              proTypeItem.proType_name_bn = ''
            }
            const maritalItem = {}
            const maritalObj = this.maritalList.find(per => per.value === item.marital_status)
            if (typeof maritalObj !== 'undefined') {
              maritalItem.marital_name = maritalObj.text_en
              maritalItem.marital_name_bn = maritalObj.text_bn
            } else {
              maritalItem.marital_name = ''
              maritalItem.marital_name_bn = ''
            }
            const bloodItem = {}
            const bloodObj = this.bloodGroupList.find(blood => blood.value === item.blood_group)
            if (typeof bloodObj !== 'undefined') {
              bloodItem.blood_name = bloodObj.text
              bloodItem.blood_name_bn = bloodObj.text_bn
            } else {
              bloodItem.blood_name = ''
              bloodItem.blood_name_bn = ''
            }
            const religionItem = {}
            const religionObj = this.religionList.find(religion => religion.value === item.religion)
            if (typeof religionObj !== 'undefined') {
              religionItem.religion_name = religionObj.text_en
              religionItem.religion_name_bn = religionObj.text_bn
            } else {
              religionItem.religion_name = ''
              religionItem.religion_name_bn = ''
            }

            const officeTypeItem = {}
            const officeTypeObj = this.officeTypeList.find(office => office.value === parseInt(item.professional_info.office_type_id))
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeItem.office_type_name = officeTypeObj.text_en
              officeTypeItem.office_type_name_bn = officeTypeObj.text_bn
            } else {
              officeTypeItem.office_type_name = ''
              officeTypeItem.office_type_name_bn = ''
            }

            const officeItem = {}
            const officeObj = this.officeList.find(per => parseInt(per.value) === parseInt(item.professional_info.office_id))
            if (typeof officeObj !== 'undefined') {
              officeItem.office_name = officeObj.text_en
              officeItem.office_name_bn = officeObj.text_bn
            } else {
              officeItem.office_name = ''
              officeItem.office_name_bn = ''
            }

            return Object.assign({}, item, customItem, orgItem, designationItem, gradeItem, areaItem, perareaItem, divisionItem, perdivisionItem, districtItem, perdistrictItem, upazillaItem, perupazillaItem, cityCorporationItem, percityCorporationItem, unionItem, perunionItem, wardItem, perwardItem, pauroshobaItem, perpauroshobaItem, genderItem, maritalItem, bloodItem, religionItem, jobTypeItem, proTypeItem, officeTypeItem, officeItem)
          })
          return listData
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        },
        pdfExport () {
          const reportTitle = this.$i18n.locale === 'en' ? 'Registration' : 'নিবন্ধন'
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
        }
    }
}
</script>
<style>
.wid span {
    display: block;
    width: 100px;
    overflow-wrap: anywhere;
}
</style>
